import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import PublicOne from "./pages/publicone.jsx"
import PublicTwo from "./pages/publictwo.jsx"
import PublicTwoFive from "./pages/publictwofive.jsx"
import PublicThree from "./pages/publicthree.jsx"
import PublicFour from "./pages/publicfour.jsx"
import PublicFive from "./pages/publicfive.jsx"
import ThankYou from "./pages/thankyou.jsx"

function App() {
  return (
    <div>
        <BrowserRouter>
            <Routes>
                <Route
                    path={"/"}
                    element={<PublicTwoFive />}
                />
                
                <Route
                    path={"/mint1"}
                    element={<PublicOne />}
                />
                <Route
                    path={"/mint2"}
                    element={<PublicTwo />}
                />
                <Route
                    path={"/mint25"}
                    element={<PublicTwoFive />}
                />
                <Route
                    path={"/mint3"}
                    element={<PublicThree />}
                />
                <Route
                    path={"/mint4"}
                    element={<PublicFour />}
                />
                <Route
                    path={"/mint5"}
                    element={<PublicFive />}
                />
                <Route
                    path={"/thankyou"}
                    element={<ThankYou />}
                />

                <Route
                    path={"/mint1-2"}
                    element={<PublicOne />}
                />
                <Route
                    path={"/mint2-2"}
                    element={<PublicTwo />}
                />
                <Route
                    path={"/mint25-2"}
                    element={<PublicTwoFive />}
                />
                <Route
                    path={"/mint3-2"}
                    element={<PublicThree />}
                />
                <Route
                    path={"/mint4-2"}
                    element={<PublicFour />}
                />
                <Route
                    path={"/mint5-2"}
                    element={<PublicFive />}
                />
            </Routes>
        </BrowserRouter>        
    </div>
  );
}

export default App;
