import React, { useState, useEffect } from 'react';
import Config from "../config/index.js"
import {ethers} from 'ethers'
import ReactPixel from "react-facebook-pixel";

export default function useMintFive(contractSales, signer, provider, address) {
    const [minted, setMinted] = useState();
    const [maxSupply, setMaxSupply] = useState();
    
    const [error, setError] = useState();
    const [isMinting, setIsMinting] = useState();
    const [txHash, setTxHash] = useState();
    
    const price = ethers.utils.parseEther("0.5");

    function queryContracts() {
        if (contractSales && address) {
            contractSales.maxSupply()
                .then(value => setMaxSupply(value.toNumber()))
                .catch(e => console.log(e));
            
            contractSales.minted()
                .then(value => setMinted(value.toNumber()))
                .catch(e => console.log(e));
        }
    }

    function setCorrectErrorMessage(e) {
        setError("An unexpected error occured and your transaction did not go through.");
        for (let error of Config.ERRORS.MESSAGES) {
            if (e.message.includes(error.error)) {
                let _msg = error.message;
                if (error.insert)
                    _msg = _msg.replace("[amount]", ethers.utils.formatEther(price.toString()))
                    setError(_msg)
                return;
            }
        }
    }

    async function mint(amount) {
        if (contractSales && signer) {
            setError(undefined);
            try {
                setIsMinting(true)
                
                const tx = await contractSales.connect(signer).mintForFive(amount, { value: price.mul(amount) });
                setTxHash(tx.hash);
                await tx.wait();
                queryContracts();

                //ReactPixel.trackSingle("682505662782161", "purchase");
                //ReactPixel.trackSingle("435357978349430", "purchase");
                //ReactPixel.trackSingle("537919504212162", "purchase");
                //ReactPixel.trackSingle("364424198891155", "purchase");
                //ReactPixel.trackSingle("267885405476370", "purchase");
                //window.fbq("track", "Purchase")
                window.open("/thankyou", "_self");
                
            } catch (e) {
                setCorrectErrorMessage(e);
            }

            setIsMinting(false);
            setTxHash(undefined);
        } else {
            alert("wallet not loaded");
        }
    }

    function isDataLoaded() {
        return minted !== undefined &&
               maxSupply !== undefined              
    }

    function isSoldOut() {
        if (isDataLoaded()) {
            return minted >= maxSupply;
        } else {
            return false
        }
    }

    useEffect(() => {
        if (provider && contractSales && address) {
            queryContracts();
            provider.on("block", _ => {
                console.log("block update")
                queryContracts();
            })
            return () => provider.off("block");
        }
    }, [provider, contractSales, address])

    return [
        isDataLoaded,
        isSoldOut,
        mint,
        isMinting,
        error,
        txHash
    ]
}