import React, {useState, useEffect} from "react"
import Config from "../config"
import ReactPixel from "react-facebook-pixel";
import $ from 'jquery';

export default function PublicMintView({
    connect,
    isConnected,
    isDataLoaded,
    isSoldOut,
    mint,
    isMinting,
    error,
    txHash,
    price
}) {

    /*useEffect(() => {
        ReactPixel.init("682505662782161");
        ReactPixel.init("435357978349430");
        ReactPixel.init("537919504212162");
        ReactPixel.init("364424198891155");
        ReactPixel.init("267885405476370");

        ReactPixel.pageView()
        ReactPixel.pageView()
        ReactPixel.pageView()
        ReactPixel.pageView()
        ReactPixel.pageView()
    }, [])*/

    useEffect(()=>{
        window.Webflow && window.Webflow.destroy();
        window.Webflow && window.Webflow.ready();
        window.Webflow && window.Webflow.require('ix2');
        //document.dispatchEvent(new Event('readystatechange'))
    });

    useEffect(() => {
        if (isConnected && isDataLoaded()) {            
            mint(amount)
        }
      }, [isConnected, isDataLoaded()])

    useEffect(() => {
        $(document).ready(function(){
            $('.menu-button.w-nav-button').click(function() {
              $('.w-nav-overlay').toggleClass("slide-left");
          });
              });
    }, [])

    const [amount, setAmount] = useState(1);

    const incrementAmount = () => {
        setAmount(a => a + 1)
    }

    const decrementAmount = () => {
        if (amount > 1) {
            setAmount(a => a - 1)
        }
    }    
    
    const UserMint = () => {
        return (
            <>
                <div className="section wf-section">
                    <div className="div-block-2">
                    <h1 className="heading-10">WHITELIST : <span className="text-span">SOLD OUT</span></h1>
                    <h1 className="heading">PUBLIC MINT IS LIVE</h1>
                    <div className="w-embed">
                        <div>
                            <h1 className="heading-text-1">Total: <span id="totalETH">{(price * amount).toFixed(price.length === 3 ? 1 : 2)}</span> ETH 
                            {
                                amount >= 4 &&
                                <span id="strike-through" className="eth-strike strikeout">{(price * amount *2 ).toFixed(price.length === 3 ? 1 : 2)} ETH</span>
                            }
                            </h1>
                        </div>

                        {
                            !isMinting &&
                            <>
                                <div id="spinner" className="spinner" style={{color: "white", animation: "none"}}>
                                    <button onClick={decrementAmount}  type="button" className="btn-minus" style={{
                                                        background: "white",
                                                        position: "relative",
                                                        color:" #445c7f",
                                                        fontWeight: "700",
                                                        textAlign: "center",
                                                        letterSpacing: "2px",
                                                        backgroundPosition: "0 -15",
                                                        padding:"7px 20px",
                                                        border: "0px solid black",
                                                        borderRadius: "88px",
                                                        fontSize: "20px",
                                                        marginRight: "10px"
                                                        }}>-</button>
                                    <input value={amount} onChange={() => 1} type="text" data-ruler="quantity" id="totalNFT" style={{
                                                                border: "0px solid black",
                                                                textAlign: "center",
                                                                background: "white",
                                                                color: "#445c7f", 
                                                                paddingTop: "5px",
                                                                paddingBottom: "5px",
                                                                borderRadius: "50px",
                                                                width: "200px",
                                                                lineHeight: "1.5",
                                                                fontSize: "20px"
                                                            }} readonly="" />
                                    <button onClick={incrementAmount} type="button" className="btn-plus" style={{
                                                        background: "white",
                                                        position: "relative",
                                                        color: "#445c7f",
                                                        fontWeight: "700",
                                                        textAlign: "center",
                                                        letterSpacing: "2px",
                                                        backgroundPosition: "0 -15",
                                                        padding: "7px 20px",
                                                        border: "0px solid black",
                                                        borderRadius: "88px",
                                                        fontSize: "20px",
                                                        marginLeft: "10px"
                                                        }}>+</button>
                                </div>
                                <br/>
                                <p className="tracking-wider" id="status">Please connect your Wallet before minting.</p>
                                <br/>
                                <div className="flex items-center justify-center text-base md:text-xl space-x-2">
                                    <br/>
                                    <span onClick={() => !isConnected ? connect() : mint(amount)} className="bg-white btn text-sea-600 button-2" id="checkoutBtn" style={{cursor: "pointer"}}>
                                        MINT NOW
                                    </span>
                                </div>
                            </>
                        }

                        {
                            isMinting && !error &&
                            <> 
                                <div className="flex items-center justify-center text-base md:text-xl space-x-2">
                                    <br/>
                                    <a href={Config.URLS.etherscan + txHash} target="_blank" className="bg-white btn text-sea-600 button-2" id="checkoutBtn" style={{cursor: "pointer"}}>
                                        VIEW TRANSACTION
                                    </a>
                                </div>
                            </>
                        }

                        {
                            error &&
                            <>
                                <br/><br/>
                                <p className="tracking-wider top-10" style={{color: "red"}}> {error} </p>
                            </>
                        }
                        
                        <br/><br/>
                        <p className="tracking-wider top-10"> Mint a minimum of 4 NFTs to get 50% OFF </p>
                    </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <div className="nav-main wf-section">
                <div data-animation="over-right" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" data-doc-height="1" role="banner" className="navbar w-nav">
                <div className="wrap-menu">
                    <div className="brand">
                    <a href="index.html" aria-current="page" className="w-nav-brand w--current"><img src="images/61f13e4740dcb97fd3ecfc7d_tête-blanc-2.png" loading="lazy" sizes="100px" width="100" srcset="images/61f13e4740dcb97fd3ecfc7d_tête-blanc-2-p-500.png 500w, images/61f13e4740dcb97fd3ecfc7d_tête-blanc-2-p-800.png 800w, images/61f13e4740dcb97fd3ecfc7d_tête-blanc-2-p-1080.png 1080w, images/61f13e4740dcb97fd3ecfc7d_tête-blanc-2.png 4000w" alt=""/></a>
                    </div>
                    <div className="menu-link">
                    <nav role="navigation" className="nav-menu w-nav-menu">
                        <a href="#story" className="nav-link-4 w-nav-link">STORY</a>
                        <a href="#roadmap" className="nav-link w-nav-link">ROADMAP</a>
                        <a href="#team" className="nav-link-3 w-nav-link">TEAM</a>
                        <a href="#faq" className="nav-link-2 w-nav-link">FAQ</a>
                        <div id="menu-btn" className="menu-btn">
                        <a href="https://mint.mutantapeplanet.com/" target="_blank" className="button hover-btn-dark w-button"><strong className="bold-text">MINT NOW</strong></a>
                        </div>
                    </nav>
                    </div>
                    <div className="menu-btn">
                    <div className="menu-button w-nav-button">
                        <div className="w-embed"><svg className="m-icon" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 730 630" width="730" height="630" preserveaspectratio="xMidYMid meet" style={{
                                width: "100%",
                                height: "100%",
                                transform: "translate3d(0px, 0px, 0px)"
                        }}>
                            <defs>
                            <clippath id="__lottie_element_3">
                                <rect width="730" height="630" x="0" y="0"></rect>
                            </clippath>
                            </defs>
                            <g clip-path="url(#__lottie_element_3)">
                            <g transform="matrix(-0.7071067690849304,-0.7071067690849304,0.7071067690849304,-0.7071067690849304,489.8043518066406,189.69564819335938)" opacity="1" style={{display: "none"}}>
                                <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                                <path stroke-linecap="round" stroke-linejoin="round" fill-opacity="0" stroke="rgb(255,255,255)" stroke-opacity="1" stroke-width="55" d=" M-327,-176.5 C-327,-176.5 327,-176.5 327,-176.5"></path>
                                </g>
                            </g>
                            <g transform="matrix(-0.7071067690849304,0.7071067690849304,-0.7071067690849304,-0.7071067690849304,489.8043518066406,438.3043518066406)" opacity="1" style={{display: "none"}}>
                                <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                                <path stroke-linecap="round" stroke-linejoin="round" fill-opacity="0" stroke="rgb(255,255,255)" stroke-opacity="1" stroke-width="55" d=" M-327,176.5 C-327,176.5 327,176.5 327,176.5"></path>
                                </g>
                            </g>
                            <g transform="matrix(1,-0.000004271669695299352,0.000004271669695299352,1,365.0007629394531,668.067626953125)" opacity="1" style={{display: "block"}}>
                                <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                                <path stroke-linecap="round" stroke-linejoin="round" fill-opacity="0" stroke="rgb(255,255,255)" stroke-opacity="1" stroke-width="55" d=" M-327,-176.5 C-327,-176.5 327,-176.5 327,-176.5"></path>
                                </g>
                            </g>
                            <g transform="matrix(1,-0.000007119449492165586,0.000007119449492165586,1,364.9987487792969,-38.06880187988281)" opacity="1" style={{display: "block"}}>
                                <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                                <path stroke-linecap="round" stroke-linejoin="round" fill-opacity="0" stroke="rgb(255,255,255)" stroke-opacity="1" stroke-width="55" d=" M-327,176.5 C-327,176.5 327,176.5 327,176.5"></path>
                                </g>
                            </g>
                            </g>
                        </svg>
                        </div>
                        <div className="icon w-icon-nav-menu"></div>
                    </div>
                    <a href="https://mint.mutantapeplanet.com/" target="_blank" className="button hover-btn-dark w-button"><strong className="bold-text">MINT NOW</strong></a>
                    </div>
                </div>
                </div>
            </div>
            <div className="wf-section">
                <img src="images/61f3dec79b450b3b86282b69_Bm4.png" loading="lazy" sizes="100vw" srcset="images/61f3dec79b450b3b86282b69_Bm4-p-500.png 500w,images/61f3dec79b450b3b86282b69_Bm4.png 585w" alt="" className="image-10"/><img src="images/61eef87e7348491aefc6a21f_B3.png" loading="lazy" sizes="(max-width: 1920px) 100vw, 1920px" srcset="images/61eef87e7348491aefc6a21f_B3-p-500.png 500w, images/61eef87e7348491aefc6a21f_B3-p-800.png 800w, images/61eef87e7348491aefc6a21f_B3-p-1080.png 1080w, images/61eef87e7348491aefc6a21f_B3-p-1600.png 1600w, images/61eef87e7348491aefc6a21f_B3.png 1920w" alt="" className="image-9"/></div>
            {/*<div className="section wf-section">
                <div className="div-block-2">
                <h1 className="heading-10">WHITELIST : <span className="text-span">SOLD OUT</span></h1>
                <h1 className="heading">PUBLIC MINT IS LIVE</h1>
                <div className="w-embed">
                    <div>
                        <h1 className="heading-text-1">Total: <span id="totalETH">0.60</span> ETH <span id="strike-through" className="eth-strike strikeout">1.0 ETH</span></h1>
                    </div>
                    <div id="spinner" className="spinner" style={{color: "white", animation: "none"}}>
                    <button type="button" className="btn-minus" style={{
                                        background: "white",
                                        position: "relative",
                                        color:" #445c7f",
                                        fontWeight: "700",
                                        textAlign: "center",
                                        letterSpacing: "2px",
                                        backgroundPosition: "0 -15",
                                        padding:"7px 20px",
                                        border: "0px solid black",
                                        borderRadius: "88px",
                                        fontSize: "20px",
                                        marginRight: "10px"
                                        }}>-</button>
                    <input type="text" value="4" data-ruler="quantity" id="totalNFT" style={{
                                                border: "0px solid black",
                                                textAlign: "center",
                                                background: "white",
                                                color: "#445c7f", 
                                                paddingTop: "5px",
                                                paddingBottom: "5px",
                                                borderRadius: "50px",
                                                width: "200px",
                                                lineHeight: "1.5",
                                                fontSize: "20px"
                                            }} readonly="" />
                    <button type="button" className="btn-plus" style={{
                                        background: "white",
                                        position: "relative",
                                        color: "#445c7f",
                                        fontWeight: "700",
                                        textAlign: "center",
                                        letterSpacing: "2px",
                                        backgroundPosition: "0 -15",
                                        padding: "7px 20px",
                                        border: "0px solid black",
                                        borderRadius: "88px",
                                        fontSize: "20px",
                                        marginLeft: "10px"
                                        }}>+</button>
                    </div>
                    <br/>
                    <p className="tracking-wider" id="status">Please connect your Wallet before minting.</p>
                    <br/>
                    <div className="flex items-center justify-center text-base md:text-xl space-x-2">
                        <br/>
                    <a className="bg-white btn text-sea-600 button-2" id="checkoutBtn" style={{cursor: "pointer"}}>
                        MINT NOW
                    </a>
                    </div><br/><br/>
                    <p className="tracking-wider top-10"> Mint a minimum of 4 NFTs to get 50% OFF </p>
                </div>
                </div>
            </div>*/}
            <UserMint />
            <div id="story" className="section-2 wf-section">
                <div className="div-block-10">
                <div className="columns-4 w-row">
                    <div className="w-col w-col-6"><img src="images/Logan-PaulYellow-p-500.jpeg" loading="lazy" width="200" alt="" className="image-8"/>
                    <h1 className="heading-9">Logan Paul</h1>
                    <p className="paragraph-6">Minted 5 Mutant Apes last week</p>
                    <a href="https://twitter.com/loganpaul" target="_blank" className="w-inline-block"><img src="images/61ed9ecf829f00c213ec32f2_61a7d383622c6f6ac561a63e_on-off-button.png" loading="lazy" width="60" sizes="(max-width: 479px) 27vw, 60px" srcset="images/61ed9ecf829f00c213ec32f2_61a7d383622c6f6ac561a63e_on-off-button-p-500.png 500w, images/61ed9ecf829f00c213ec32f2_61a7d383622c6f6ac561a63e_on-off-button.png 512w" alt=""/></a>
                    </div>
                    <div className="column-8 w-col w-col-6"><img src="images/Snoop-DoggBlue-p-500.png" loading="lazy" width="200" alt="" className="image-8"/>
                    <h1 className="heading-9">Snoop Dogg</h1>
                    <p className="paragraph-6">Has just joined the Club</p>
                    <a href="https://twitter.com/SnoopDogg" className="w-inline-block"><img src="images/61ed9ecf829f00c213ec32f2_61a7d383622c6f6ac561a63e_on-off-button.png" loading="lazy" width="60" sizes="(max-width: 479px) 27vw, 60px" srcset="images/61ed9ecf829f00c213ec32f2_61a7d383622c6f6ac561a63e_on-off-button-p-500.png 500w, images/61ed9ecf829f00c213ec32f2_61a7d383622c6f6ac561a63e_on-off-button.png 512w" alt=""/></a>
                    </div>
                </div>
                <div className="columns project-text w-row">
                    <div className="column-2 w-col w-col-6 w-col-small-small-stack w-col-tiny-tiny-stack">
                    <div className="div-block-4 image-block">
                        <img src="images/61edcda4113988345ff183ba_6a7c3bca-3d78-4eb2-b290-10a8d44359e3.jpg" 
                        loading="lazy" sizes="(max-width: 479px) 42vw, (max-width: 767px) 43vw, (max-width: 991px) 
                        17vw, 15vw" srcset="images/61edcda4113988345ff183ba_6a7c3bca-3d78-4eb2-b290-10a8d44359e3-p-500.jpeg 
                        500w, images/61edcda4113988345ff183ba_6a7c3bca-3d78-4eb2-b290-10a8d44359e3-p-800.jpeg 800w, 
                        images/61edcda4113988345ff183ba_6a7c3bca-3d78-4eb2-b290-10a8d44359e3-p-1080.jpeg 1080w, 
                        images/61edcda4113988345ff183ba_6a7c3bca-3d78-4eb2-b290-10a8d44359e3.jpg 1280w" alt="" className="image-2"/>
                        <img src="images/61edcdb28cb41f324d920d11_6ec0089e-2c3b-458c-a160-959069711b75.jpg" 
                        loading="lazy" id="w-node-_9369f4c8-5bd5-59b9-02d2-e25d88574b0c-383111be" 
                        sizes="(max-width: 479px) 42vw, (max-width: 767px) 43vw, (max-width: 991px) 
                        17vw, 15vw" srcset="images/61edcdb28cb41f324d920d11_6ec0089e-2c3b-458c-a160-959069711b75-p-500.jpeg 
                        500w, images/61edcdb28cb41f324d920d11_6ec0089e-2c3b-458c-a160-959069711b75-p-800.jpeg 800w, 
                        images/61edcdb28cb41f324d920d11_6ec0089e-2c3b-458c-a160-959069711b75-p-1080.jpeg 1080w, 
                        images/61edcdb28cb41f324d920d11_6ec0089e-2c3b-458c-a160-959069711b75.jpg 1280w" alt="" className="image"/>
                        <img src="images/61edcdbc1b7475382c726056_6f01632c-32e5-4f84-9f13-f462e92acb0c.jpg" 
                        loading="lazy" id="w-node-_4e4fb5e2-156c-e077-c564-c8ac2c41f088-383111be" 
                        sizes="(max-width: 479px) 42vw, (max-width: 767px) 43vw, (max-width: 991px) 
                        17vw, 15vw" srcset="images/61edcdbc1b7475382c726056_6f01632c-32e5-4f84-9f13-f462e92acb0c-p-500.jpeg 
                        500w, images/61edcdbc1b7475382c726056_6f01632c-32e5-4f84-9f13-f462e92acb0c-p-800.jpeg 800w, 
                        images/61edcdbc1b7475382c726056_6f01632c-32e5-4f84-9f13-f462e92acb0c-p-1080.jpeg 1080w, 
                        images/61edcdbc1b7475382c726056_6f01632c-32e5-4f84-9f13-f462e92acb0c.jpg 1280w" alt="" className="image-3"/><img src="images/61edcdc73b330e13ae66357c_900e7a18-c4c0-4e62-899c-065985a2450b.jpg" loading="lazy" id="w-node-_9970a372-3f9d-0d82-320f-acbcb13df2f9-383111be" sizes="(max-width: 479px) 42vw, (max-width: 767px) 43vw, (max-width: 991px) 17vw, 15vw" srcset="images/61edcdc73b330e13ae66357c_900e7a18-c4c0-4e62-899c-065985a2450b-p-500.jpeg 500w, images/61edcdc73b330e13ae66357c_900e7a18-c4c0-4e62-899c-065985a2450b-p-800.jpeg 800w, images/61edcdc73b330e13ae66357c_900e7a18-c4c0-4e62-899c-065985a2450b-p-1080.jpeg 1080w, images/61edcdc73b330e13ae66357c_900e7a18-c4c0-4e62-899c-065985a2450b.jpg 1280w" alt="" className="image-4"/></div>
                    </div>
                    <div className="column w-col w-col-6 w-col-small-small-stack w-col-tiny-tiny-stack">
                    <h1 className="heading-2">Project Explanation</h1>
                    <p className="paragraph">The Mutant Apes Planet (MAP) is an NFT collection of 9,999 Mutant Ape Planet NFT with a community focus. Each NFT is algorithmically generated using a combination of over 150 attributes such as hats, eyes, skins and much more !</p>
                    <a href="https://discord.com/invite/mutantapeplanet" target="_blank" className="button hover-btn-dark w-button"><strong className="bold-text">JOIN DISCORD</strong></a>
                    </div>
                </div>
                </div>
            </div>
            <div id="roadmap" className="section-3 wf-section">
                <h1 className="heading-3">ROADMAP</h1>
                <p className="paragraph-2"><strong>Here is our plan of action with a basic framework. We are stalwart believers in hard work and creativity. Let’s look at the goals we strive to achieve on our journey.</strong></p>
                <div className="columns-2 w-row">
                <div className="column-3 w-col w-col-6">
                    <div className="div-block-5"><img src="images/Screenshot_3.png" loading="lazy" alt=""/>
                    <h3 className="heading-4"><strong className="bold-text-3">PRE-MINT</strong></h3>
                    <div className="div-block-7">
                        <p className="paragraph-3">- $500,000 in Marketing Budget</p>
                        <p className="paragraph-3">- NFT + Whitelist slots giveaways on our socials </p>
                    </div>
                    </div>
                </div>
                <div className="column-4 w-col w-col-6">
                    <div className="div-block-6"><img src="images/Screenshot_4.png" loading="lazy" alt=""/>
                    <h3 className="heading-4"><strong className="bold-text-3">POST SELL-OUT</strong></h3>
                    <div className="div-block-7">
                        <p className="paragraph-3">- Launch of our Merch Collection</p>
                        <p className="paragraph-3">- Creation of a MAP token with staking features (De-Fi)</p>
                        <p className="paragraph-3">- Community wallet for marketing campaigns and raffles among our holders</p>
                        <p className="paragraph-3">- Acquisition of Metaverse land and expansion</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div id="team" className="team wf-section">
                <h1 className="heading-3"><strong className="bold-text-4">TEAM</strong></h1>
                <div className="div-block-8">
                <div className="columns-3 w-row">
                    <div className="column-5 w-col w-col-3 w-col-small-6 w-col-tiny-6"><img src="images/-1-1.jpeg" loading="lazy" width="200" alt="" className="image-5"/>
                    <h1 className="heading-6"><strong>JAMES</strong></h1>
                    <h3 className="heading-8"><strong className="bold-text-5">Founder and Investor<br/>BAYC Holder</strong></h3>
                    </div>
                    <div className="column-6 w-col w-col-3 w-col-small-6 w-col-tiny-6"><img src="images/61f41d122a6c60c675211e20_photo_2022-01-23_15-10-34.jpg" loading="lazy" width="200" alt="" className="image-5"/>
                    <h1 className="heading-6"><strong>TIM</strong></h1>
                    <h3 className="heading-8"><strong className="bold-text-5">Founder<br/>MAYC Holder</strong></h3>
                    </div>
                    <div className="column-7 w-col w-col-3 w-col-small-6 w-col-tiny-6"><img src="images/61eef40c55cda6454ca60371_82d7d7dc-f753-439f-80fc-36fa48883157.jpg" loading="lazy" sizes="(max-width: 767px) 41vw, (max-width: 991px) 20vw, 200px" width="200" srcset="images/61eef40c55cda6454ca60371_82d7d7dc-f753-439f-80fc-36fa48883157-p-500.jpeg 500w, images/61eef40c55cda6454ca60371_82d7d7dc-f753-439f-80fc-36fa48883157-p-800.jpeg 800w, images/61eef40c55cda6454ca60371_82d7d7dc-f753-439f-80fc-36fa48883157-p-1080.jpeg 1080w, images/61eef40c55cda6454ca60371_82d7d7dc-f753-439f-80fc-36fa48883157.jpg 1280w" alt="" className="image-5"/>
                    <h1 className="heading-6"><strong>ARTHUR</strong></h1>
                    <h3 className="heading-8"><strong className="bold-text-5">Founder<br/>Blockchain Specialist</strong></h3>
                    </div>
                    <div className="w-col w-col-3 w-col-small-6 w-col-tiny-6"><img src="images/61edcdbc1b7475382c726056_6f01632c-32e5-4f84-9f13-f462e92acb0c.jpg" loading="lazy" sizes="(max-width: 767px) 41vw, (max-width: 991px) 20vw, 200px" width="200" srcset="images/61edcdbc1b7475382c726056_6f01632c-32e5-4f84-9f13-f462e92acb0c-p-500.jpeg 500w, images/61edcdbc1b7475382c726056_6f01632c-32e5-4f84-9f13-f462e92acb0c-p-800.jpeg 800w, images/61edcdbc1b7475382c726056_6f01632c-32e5-4f84-9f13-f462e92acb0c-p-1080.jpeg 1080w, images/61edcdbc1b7475382c726056_6f01632c-32e5-4f84-9f13-f462e92acb0c.jpg 1280w" alt="" className="image-5"/>
                    <h1 className="heading-6"><strong>LUCAS</strong></h1>
                    <h3 className="heading-8"><strong className="bold-text-5">Artist and Crypto Investor<br/>Since 2015</strong></h3>
                    </div>
                </div>
                </div>
            </div>
            <div id="faq" className="faq wf-section">
                <div>
                <h1 className="heading-3"><strong className="bold-text-4">FREQUENTLY ASKED QUESTIONS</strong></h1>
                </div>
                <div className="div-block-9">
                <div data-hover="false" data-delay="0" className="dropdown w-dropdown">
                    <div className="dropdown-toggle w-dropdown-toggle">
                    <div className="w-icon-dropdown-toggle"></div>
                    <div className="text-block">What is an NFT ?</div>
                    </div>
                    <nav className="dropdown-list w-dropdown-list">
                    <p className="paragraph-4">NFT stands for &#x27;non-fungible token&#x27;.<br/><br/>A NFT is basically data that is accounted for in a digital ledger, and that data represents something specific and unique.<br/><br/>A NFT can, for example, represent a unique piece of art or a game token.</p>
                    </nav>
                </div>
                <div data-hover="false" data-delay="0" className="dropdown w-dropdown">
                    <div className="dropdown-toggle w-dropdown-toggle">
                    <div className="w-icon-dropdown-toggle"></div>
                    <div className="text-block">How Can I Get an NFT</div>
                    </div>
                    <nav className="dropdown-list w-dropdown-list">
                    <p className="paragraph-4">It will be available for purchase on our website through an initial sale.<br/><br/>At the time of purchase, a randomly selected NFT will be minted on the blockchain and delivered to your wallet and OpenSea account.<br/><br/>Once the public sale is closed, you can get a Mutant Ape on the secondary market (on OpenSea).</p>
                    </nav>
                </div>
                <div data-hover="false" data-delay="0" className="dropdown w-dropdown">
                    <div className="dropdown-toggle w-dropdown-toggle">
                    <div className="w-icon-dropdown-toggle"></div>
                    <div className="text-block">How to purchase One ?</div>
                    </div>
                    <nav className="dropdown-list w-dropdown-list">
                    <p className="paragraph-4">The easiest way is to install a MetaMask extension in your Chrome browser and link your Ethereum wallet to it.<br/><br/>Ensure you have enough ETH in your wallet to cover the cost of a NFT plus the associated transaction (gas) fees.<br/><br/>Approve the transaction on MetaMask and you are all set.<br/><br/>The Mutant Ape will be available to mint on our official website.</p>
                    </nav>
                </div>
                <div data-hover="false" data-delay="0" className="dropdown w-dropdown">
                    <div className="dropdown-toggle w-dropdown-toggle">
                    <div className="w-icon-dropdown-toggle"></div>
                    <div className="text-block">What is the total supply ?</div>
                    </div>
                    <nav className="dropdown-list w-dropdown-list">
                    <p className="paragraph-4">9999 unique Mutant Ape will be created.</p>
                    </nav>
                </div>
                <div data-hover="false" data-delay="0" className="dropdown w-dropdown">
                    <div className="dropdown-toggle w-dropdown-toggle">
                    <div className="w-icon-dropdown-toggle"></div>
                    <div className="text-block">How much does it cost to mint an NFT ?</div>
                    </div>
                    <nav className="dropdown-list w-dropdown-list">
                    <p className="paragraph-4">Mint Price will be 0,15 ETH + Gas</p>
                    </nav>
                </div>
                <div data-hover="false" data-delay="0" className="dropdown w-dropdown">
                    <div className="dropdown-toggle w-dropdown-toggle">
                    <div className="w-icon-dropdown-toggle"></div>
                    <div className="text-block">Where can i view my NFT ?</div>
                    </div>
                    <nav className="dropdown-list w-dropdown-list">
                    <p className="paragraph-4">Once minted, you&#x27;ll just need to login into your OpenSea account to view your NFTs.</p>
                    </nav>
                </div>
                </div>
            </div>
            <div className="section faq wf-section">
                <div className="div-block-2">
                <h1 className="heading"><strong>PHASE 1 SOLD OUT</strong></h1>
                <a href="https://discord.com/invite/mutantapeplanet" target="_blank" className="button-2 w-button"><strong>JOIN DISCORD</strong></a>
                </div>
            </div>
            <div className="footer wf-section"><img src="images/61f13d1f9a1607c9acab6ab1_logo-blanc-1-p-500.png" loading="lazy" width="200" alt=""/>
                <p className="paragraph-5">Mutant Ape Planet</p>
                <div className="footer-icon">
                <a href="https://www.instagram.com/mutantapeplanetnft/" target="_blank" className="w-inline-block"><img src="images/61ed9ecf829f001baeec32f3_61a7d383622c6f867661a63b_on-off-button.png" loading="lazy" sizes="(max-width: 479px) 52vw, 50px" width="50" srcset="images/61ed9ecf829f001baeec32f3_61a7d383622c6f867661a63b_on-off-button-p-500.png 500w, images/61ed9ecf829f001baeec32f3_61a7d383622c6f867661a63b_on-off-button.png 512w" alt="" className="image-6"/></a>
                <a href="https://twitter.com/mutantapeplanet" target="_blank" className="w-inline-block"><img src="images/61ed9ecf829f00c213ec32f2_61a7d383622c6f6ac561a63e_on-off-button.png" loading="lazy" sizes="(max-width: 479px) 52vw, 50px" width="50" srcset="images/61ed9ecf829f00c213ec32f2_61a7d383622c6f6ac561a63e_on-off-button-p-500.png 500w, images/61ed9ecf829f00c213ec32f2_61a7d383622c6f6ac561a63e_on-off-button.png 512w" alt="" className="image-7"/></a>
                </div>
            </div>
        </>
    )
}