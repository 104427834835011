const Config = {
    DATA: {
    },
    CONTRACTS: {
        SALES: "MutantApesPlanet"
    },
    NETWORK: {
        TARGET_CHAIN_ID: 1,
        CHAIN_ID_TO_NAME: {
            "1": "mainnet",
            "3": "ropsten",
            "4": "rinkeby",
            "33142": "localhost",
            "42": "kovan"
            
        },
        INFURA_ID: "e08fce9bc22d4d508e90620ba89dfa18",
        PORTIS_ID: "",
        FORTMATIC_ID: ""
    },
    URLS: {
        opensea: "https://opensea.io/collection/mutant-apes-planet",
        etherscan: "https://etherscan.io/tx/"
    },
    ERRORS: {
        MESSAGES: [
            {
                error: "MetaMask Tx Signature: User denied transaction signature",
                message: "You rejected the transaction."
            },
            {
                error: "insufficient funds for gas * price + value",
                message: "You don't have enough ETH to complete the purchase",
                insert: false
            }
        ]
    }
}

export default Config